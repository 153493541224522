//BRAND
export const name = 'Skilltors';
export const company = 'Skilltors';
export const websiteURL = 'https://skilltors.com';
export const serverURL = 'https://skilltors.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/skilltors-2b8e6.appspot.com/o/New%20Project%20(3).png?alt=media&token=bcc7e91d-c647-4d65-a6e2-3ad2eeb98350';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = false;
export const paystackEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 5;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 50;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Phuwit Kulthanapipat";
export const profession = 'Skilltors';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/skilltors-2b8e6.appspot.com/o/New%20Project%20(3).png?alt=media&token=bcc7e91d-c647-4d65-a6e2-3ad2eeb98350';

//PAYPAL
export const paypalPlanIdOne = "P-7FN68008HM9804238M2N3YAI";
export const paypalPlanIdTwo = "P-3JW98642Y6844473TM2N3ZYI";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8";
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';